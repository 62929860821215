@import './scss/variables.scss';
@import './scss/mixins.scss';
@import url('https://fonts.googleapis.com/css?family=Lato:400,700|Eczar:800|Yeseva+One&display=swap');

/////////////
//*RESETS*//
////////////

* {
  margin:0px;
  padding:0px;
}

body{
  background: $black;
  color: $white;
  min-height:120vh;
  @include SansSerif;
}
html::-webkit-scrollbar {
  display: none;
}

button{
  cursor:pointer;
}

.hidden{
  display:none;
}

/////////////////
//*TYPOGRAPHY*//
///////////////

h1{
  @include Serif;
  @include font-size(5);
  @include line-height(4);
}
h3{
  @include SansSerif;
  text-transform: uppercase;
  @include font-size(.75);
}
p{
  &.subtitle{
    margin:8px 0px 32px;
    @include font-size(1);
  }
}
.home{
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index:-1;
}
.loading-text {
	width:100%;
	height:100px;
  line-height:100px;
  text-align:center;
  .home &{
    @include center(both);
  }
	span {
		display:inline-block;
		margin:0 5px;
		color:#fff;
    font-family:'Quattrocento Sans', sans-serif;
		@for $i from 0 through 6 {
			&:nth-child(#{$i + 1}) {
				filter:blur(0px);
				animation:blur-text 1.5s (#{$i/5}s) infinite linear alternate;
			}
		}
	}
}

@keyframes blur-text {
	0% {filter:blur(0px);}
	100% {filter:blur(4px);}
}

////////////
//*ICONS*//
//////////

.icon-play{
  position:relative;
  &:before{
    content:'';
    width:8px;
    height:8px;
    @include center(vertical);
    left:12px;
    background-image: url('static/icon-play.svg');
    background-repeat: no-repeat;
  }
}

.icon-plus{
  position:relative;
  &:before{
    content:'';
    width:8px;
    height:8px;
    @include center(vertical);
    left:12px;
    background-image: url('static/icon-plus.svg');
    background-repeat: no-repeat;
  }
}

.icon-calendar{
  position:relative;
  &:before{
    content:'';
    width:16px;
    height:16px;
    @include center(vertical);
    left:10px;
    margin-top:-1px;
    background-image: url('static/icon-calendar.svg');
    background-repeat: no-repeat;
  }
}

.icon-discord{
  position:relative;
  &:before{
    content:'';
    width:16px;
    height:16px;
    @include center(vertical);
    left:10px;
    margin-top:-1px;
    background-image: url('static/icon-discord.svg');
    background-repeat: no-repeat;
  }
}

.icon-twitter{
  position:relative;
  &:before{
    content:'';
    width:16px;
    height:16px;
    @include center(vertical);
    left:10px;
    margin-top:-1px;
    background-image: url('static/icon-twitter.svg');
    background-repeat: no-repeat;
  }
}

.icon-mail{
  position:relative;
  &:before{
    content:'';
    width:16px;
    height:16px;
    @include center(vertical);
    left:10px;
    margin-top:-1px;
    background-image: url('static/icon-mail.svg');
    background-repeat: no-repeat;
  }
}

.icon-search{
  mask:url('static/icon-search.svg');
  width:16px;
  height:16px;
  background:$white;
  @include center(vertical);
  @include transition($baseTransition);
  &:hover{
    background: $red;
  }
}

////////////
//*DEFAULTS*//
//////////


.filled{
  background:$red;
  color:$white;
  border: 2px solid $red;
  padding:8px 16px 8px 32px;
  margin-right:16px;
  text-transform: uppercase;
  width:fit-content;
  text-decoration: none;
  @include border-radius(8px);
  @include transition($baseTransition);
  &:hover{
    background:transparent;
    border-color: $redHover;
  }
}
.outline{
  background:transparent;
  color:$white;
  border: 2px solid $red;
  padding: 8px 16px 8px 32px;
  margin-right:16px;
  text-transform: uppercase;
  width:fit-content;
  @include border-radius(8px);
  @include transition($baseTransition);
  &:hover{
    background: $redHover;
    border-color: $redHover;
  }
}

.btn-round{
  display:block;
  width:fit-content;
  margin:20px 0px;
  padding:20px 25px;
  border-radius:50%;
  background: $red;
  font-weight:bold;
  z-index:100;
  cursor:pointer;
  @include transition($baseTransition);
  img{
    width:24px;
    height:auto;
  }
  &:hover{
    transform:scale(1.1);
  }
}

#search-btn{
  @include transition($baseTransition);
  &:after{
    content:unset;
  }
  &:hover{
    color:$red;
  }
  #search-bar{
    transform:scaleX(0);
    transform-origin: right;
  }
  &.active{
    #search-bar{
      transform:scaleX(1);
    }
  }
}
input{
  background: $grey;
  border: none;
  color: $white;
  padding: 8px;
  margin-right: 16px;
  @include border-radius(8px);
  @include transition($baseTransition);
  &:hover{
    background: $greyHover;
  }
  &:focus{
    outline: none;
    @include elevation(5);
  }
}

.big-search{
  box-sizing: border-box;
  width:100%;
  color:$white;
  background: transparent;
  border-bottom:2px solid $greyHover;
  border-radius:unset;
  font-size:3rem;
  margin-bottom:40px;
  font-weight:300;
  &::selection {
    background: $grey; /* WebKit/Blink Browsers */
  }
  &::-moz-selection {
    background: $grey; /* Gecko Browsers */
  }
  &.fixed{
    position:fixed;
    top:0px;
    left:0;
    padding:80px 5% 10px;
    z-index:2;
    background:$black;
    box-shadow:0px 0px 20px 20px rgba(0,0,0,0.5);
    &:hover{
      background:$black;
    }
  }
  &::placeholder{
    color:$grey;
    @include transition($baseTransition);
  }
  &:hover{
    background:transparent;
    border-bottom:2px solid $white;
    &::placeholder{
      color:$white;
    }
  }
}

#main{
  &.loaded{
    opacity:1;
  }
  opacity:0;
  @include transition($baseTransition);
  padding-left:$baseSideMargins;
  padding-right:$baseSideMargins;
}
#page{
  opacity:0;
  @include transition(all .3s .3s cubic-bezier(0.1,0.67,0.76,1));
  &.loaded{
    opacity:1;
  }
}

/////////////
//*HEADER*//
///////////

header{
  position:fixed;
  top:0;
  left:0;
  display:flex;
  flex-wrap: nowrap;
  z-index:1001;
  width:100%;
  &:before{
    content:'';
    position:absolute;
    width:100%;
    height:100px;
    top:0px;
    background:linear-gradient($black, rgba(0,0,0,0));
    z-index:-1;
  }
  #logo{
    max-width:40px;
    margin:-10px 0 0 5%;
  }
  nav{
    width:90%;
    margin:auto;
    display:flex;
    align-items: center;
    li{
      display:flex;
      padding:16px;
      list-style-type: none;
      &:nth-child(6){
        margin-left:auto;
      }
    }
  }
  p{
    flex-wrap: nowrap;
  }
  a{
      justify-content: center;
      position:relative;
      color:$white;
      text-decoration: none;
      @include font-size(.75);
      font-weight:bold;
      &.active{
        &:after{
          transform:scaleX(1);
        }
      }
      &:after{
        content:'';
        position:absolute;
        top:24px;
        left:0px;
        width:100%;
        height:2px;
        background:$red;
        transform:scaleX(0);
        transform-origin: left;
        @include transition($baseTransition);
      }
      &:hover{
        &:after{
          transform:scaleX(1);
        }
      }
      &.current{
        &:after{
          transform:scaleX(1);
        }
      }
    }
    a{
      position:relative;
      color:$white;
      text-decoration: none;
      @include font-size(.75);
      font-weight:bold;
      &.active{
        &:after{
          transform:scaleX(1);
        }
      }
      &:after{
        content:'';
        position:absolute;
        top:24px;
        left:0px;
        width:100%;
        height:2px;
        background:$red;
        transform:scaleX(0);
        transform-origin: left;
        @include transition($baseTransition);
      }
      &:hover{
        &:after{
          transform:scaleX(1);
        }
      }
      &.current{
        &:after{
          transform:scaleX(1);
        }
      }
    }
}

#content{
  display:block;
}

#featured{
  #featured-image-container{
    position:relative;
    width:110%;
    left:-5%;
    height:460px;
    overflow:hidden;
    &:before{
      content:'';
      position:absolute;
      width:66%;
      height:100%;
      top:0px;
      background:linear-gradient(to right, $black 5%, rgba(0,0,0,0));
      z-index:1;
    }
    &:after{
      content:'';
      position:absolute;
      width:100%;
      height:300px;
      bottom:0px;
      background:linear-gradient(rgba(0,0,0,0), $black);
    }
    img{
      width:100%;
      height:auto;
      @include center(both);
    }
  }
  #featured-details{
    position:absolute;
    width:66%;
    max-width:600px;
    top:120px;
    z-index:1;
    p a{
      color:$red;
      text-decoration: none;
      @include transition($baseTransition);
      &:hover{
        color:$redHover;
      }
    }
    h3{
      margin-bottom:.5rem;
    }
    button{
      margin-top:20px;
      margin-right:20px;
    }
    .descr{
      margin-top:20px;
    }
  }
}

.card-container{
  display:flex;
  flex-wrap:wrap;
  justify-content: space-between;
  .full-width{
    width:100%;
    flex-grow:2;
  }
  .half-width{
    width:49%;
  }
}
.card{
  box-sizing:border-box;
  margin-top:20px;
  padding:32px;
  background:$grey;
  @include border-radius(8px);
  @include elevation(1);
  h4{
    @include Serif;
    @include font-size(3);
    line-height:3rem;
    margin-bottom:1rem;
  }
  p{
    @include font-size(1);
    @include line-height(1.5);
  }
  .btns{
    display:inline-block;
    margin-top:32px;
    a{
      margin-right:16px;
    }
  }
}

////////////
//*VIDEOS*//
///////////
.rowShadow{
  position: absolute;
  width:90%;
  height:100%;
  left:50%;
  transform:translateX(-50%);
  margin-top:-26px; 
  z-index:2;
  pointer-events: none;
  &:before{
    content:'';
    position: absolute;
    top:-20px;
    left:-1px;
    width:90px;
    height:50%;
    background: radial-gradient(closest-side, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 80%);
    background-position-x: -60px;
    background-repeat: no-repeat;
  }
  &:after{
    content:'';
    position: absolute;
    top:-20px;
    right:0px;
    width:90px;
    height:50%;
    background: radial-gradient(closest-side, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 80%);
    background-position-x: 60px;
    background-repeat: no-repeat;
  }
}
.vidRow{
  position:relative;
  display:flex;
  margin:10px 0px 40px;
  overflow: auto;
  width:100%;
  &::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    background-color: #000;
  }
  &::-webkit-scrollbar{
    width: 2px;
    height:8px;
    top:40px;
    background-color: #222;
    @include transition($baseTransition);
  }
  &::-webkit-scrollbar-thumb{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: $red;
  }
  .vidThumbContainer{
    display:inline-block;
    margin:0px 10px 0px 0px;
    padding:0px 24px 0px 0px;
    .vidThumb{
      width:300px;
      position:relative;
    }
    &:last-child{
      padding:0px;
    }
    cursor:pointer;
    @include transition($baseTransition);
    &.narrow{   
      width:120px;
    }
    &:hover{
      transform:scale(0.95);
    }
  }
}
#and-more{
  text-align: center;
  a{
    color:$red;
    text-decoration: none;
    @include transition($baseTransition);
    &:hover{
      color:$redHover;
    }
  }
}

////////////
//*ADMIN*//
//////////
.adminTab{
  display:none;
  &.active{
    display:block;
  }
  form{
    border: 1px solid $grey;
    padding:20px;
    input{
      width:80%;
      margin:10px auto;
      &[type=checkbox]{
        width:unset;
      }
      &[type=submit]{
        width:10%;
        background:$red;
        cursor:pointer;
        &:hover{
          background:$redHover;
        }
      }
    }
  }
  .rowShadow{
    position: absolute;
    width:90%;
    height:350px;
    left:50%;
    transform:translateX(-50%);
    margin-top:-26px; 
    z-index:2;
    pointer-events: none;
    &:before{
      content:'';
      position: absolute;
      top:0px;
      left:-1px;
      width:100px;
      height:100%;
      background: radial-gradient(closest-side, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 80%);
      background-position-x: -60px;
      background-repeat: no-repeat;
    }
    &:after{
      content:'';
      position: absolute;
      top:0px;
      right:0px;
      width:100px;
      height:100%;
      background: radial-gradient(closest-side, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 80%);
      background-position-x: 60px;
      background-repeat: no-repeat;
    }
  }
  .vidRow{
    position:relative;
    display:flex;
    margin:10px 0px 40px;
    overflow-x: scroll;
    overflow-y: hidden;
    width:100%;
    &::-webkit-scrollbar{
      display: none;
    }
    .vidThumbContainer{
      display:inline-block;
      margin:0px 10px 0px 0px;
      padding:0px 24px 0px 0px;
      cursor:pointer;
      @include transition($baseTransition);
      &.narrow{   
        width:120px;
      }
      &:hover{
        transform:scale(0.95);
      }
    }
  }
}

form{
  display:none;
  &.active{
    display:block;
  }
}

///////////////
/////STORES////
///////////////

#gamesList, #merchList{
  display:flex;
  justify-content: space-between;
  flex-flow:wrap;
  a{
    color:$white;
    text-decoration: none;
    @include transition($baseTransition);
    cursor:pointer;
    &:hover{
      color:$redHover;
    }
  }
  h2{
    font-size:1rem;
    margin-top:1rem;
  }
}
#gamesList{
  .gameContainer,
  .gameContainerWithDLC{
    position:relative;
    list-style-type:none;
    flex-basis:48%;
    margin-bottom:40px;
    img{
      width:100%;
    }
    .remove{
      position:absolute;
      top:-42px;
      right:-12px;
    }
  }
  .gameContainerWithDLC{
    position:relative;
    flex-basis:100%;
    background:$black;
    padding:40px;
    border:1px solid #222;
    display:flex;
    .gameThumbContainer{
      flex-basis:50%;
    }
    .gameDLCContainer{
      flex-basis:50%;
      h3{
        font-size:1rem;
        margin:0 0 1.4rem 1.5rem;
      }
      ul{
        max-height:294px;
        overflow:auto;
        &::-webkit-scrollbar-track{
          -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
          background-color: #444;
        }
        &::-webkit-scrollbar{
          width: 12px;
          background-color: #222;
        }
        &::-webkit-scrollbar-thumb{
          -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
          background-color: #555;
        }
      }
      li{
        padding:20px 14px 54px 1.5rem;
        list-style-type: none;
        @include transition($baseTransition);
        &:hover{
          background:$grey;
        }
        .DLCThumb{
          width:120px;
          float:left;
          margin-right:20px;
        }
      }
    }
    &:after{
      content:'';
      top:0px;
      left:0px;
      position:absolute;
      width:100%;
      height:100%;
      box-shadow: 0px 0px 14px 18px rgba(0,0,0,.4) inset;
      pointer-events:none;
    }
  }
}
#merchList{
  box-sizing:border-box;
  padding:40px;
  width:110%;
  margin-left:-5%;
  background:#f6f6f8;
}
#merchList{
  &:after {
    content: "";
    flex: auto;
  }
  .merchContainer{
    padding:20px;
  }
  .merchContainer,
  .merchContainerWithSubMerch{
    background:#f6f6f8;
    position:relative;
    list-style-type:none;
    flex-basis:20%;
    margin-bottom:40px;
    img{
      width:100%;
    }
    .remove{
      position:absolute;
      top:-42px;
      right:-12px;
    }
    h2, h3, a{
      color:$grey;
    }
    .merchThumbContainer{
      h2{
        @include transition($baseTransition);
        text-align: center;
      }
      img{
        @include transition($baseTransition);
      }
      &:hover{
        img{
          transform: scale(1.05);
        }
        h2{
          color:$redHover;
        }
      }
    }
  }
  .merchContainerWithSubMerch{
      position:relative;
      flex-basis:100%;
      background:#f6f6f8;
      padding:40px;
      border-bottom:1px solid #222;
      display:flex;
      .merchThumbContainer{
        flex-basis:33%;
      }
      .subMerchContainer{
        flex-basis:66%;
        h3{
          font-size:1rem;
          margin:0 0 1.4rem 1.5rem;
        }
        ul{
          max-height:294px;
          overflow:auto;
          display:flex;
          flex-wrap: wrap;
          &::-webkit-scrollbar-track{
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
            background-color: #CCC;
          }
          &::-webkit-scrollbar{
            width: 12px;
            background-color: #222;
          }
          &::-webkit-scrollbar-thumb{
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.1);
            background-color: #EEE;
          }
        }
        li{
          padding:20px 14px 54px 1.5rem;
          list-style-type: none;
          text-align: center;
          @include transition($baseTransition);
          &:hover{
            h2{
              @include transition($baseTransition);
              color:$redHover;
            }
          }
          .subMerchThumb{
            width:120px;
          }
        }
      }
    }
  }

////////////
//*FOOTER*//
//////////
#footer{
  height:220px;
}

@media only screen and (max-width: 800px) {
  #featured{
    #featured-image-container{
      img{
        width:150%;
      }
    }
  }
  .featured-image {
    width:115%;
  }
  .card-container .half-width{
    width:100%;
  }
  #merchList .merchContainer{
    flex-basis:20%;
  }
}

@media only screen and (max-width: 600px) {
  #merchList .merchContainer{
    flex-basis:100%;
  }
  #featured #featured-image-container{
    height:400px;
  }
  .filled{
    font-size:.75rem;
  }
  .vidRow{
    &::-webkit-scrollbar{
      display:none;
    }
  }
  .vidThumbContainer{
    .vidThumb, .vidTallThumb{
      width:200px !important;
      height:auto;
    }
  }
  .rowShadow{
    display:none;
  }
  #gamesList{
    text-align:center;
    .gameContainer{
      flex-basis:100%; 
    }
    .gameContainerWithDLC{
      display:block;
      padding:4px;
      .gameThumbContainer{
        margin-bottom:1rem;
      } 
      .gameDLCContainer{
        ul{
          background:$grey;
        }
        li{
          padding: 20px 14px 0px 1.5rem;
          .DLCThumb{
            width:100%;
          }
        }
      }
    }
  }
  #featured{
    #featured-image-container{
      img{
        width:200%;
      }
    }
    #featured-details{
      width:90%;
      h1{
        font-size:3rem;   
        line-height:3rem;
      }
    }
  }
  .card{
    h4{
      font-size:2rem;
      line-height:2rem;
    }
  }
}
@media only screen and (max-width: 464px) {
  header{
    nav{
      position:fixed;
      bottom:0px;
      padding:0 5%;
      width:100%;
      justify-content: space-between;
      background:$red;
      box-shadow:inset rgba(0,0,0,0.4) -2px 2px 12px;
      box-sizing: border-box;
      li{
        padding:16px 2% 24px;
        &:last-child{
          position:fixed;
          top:0;
          right:5%;
        }
      }
    }
    a{
      &:after{
        background:$yellow;
      }
    }
  }
  #featured{
    .filled{
      font-size:.75rem;
    }
  }
  .big-search{
    font-size:1rem;
    &::placeholder{
      color:#CCC;
    }
  }
}