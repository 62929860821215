@mixin elevation($height){
	@if $height == 0{
	  box-shadow: 0 1px 3px rgba(0,0,0,0.06), 0 1px 2px rgba(0,0,0,0.12);
	}
	@if $height == 1{
	  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	}
	@else if $height == 2{
  	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
	}
	@else if $height == 3{
	  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
	}
	@else if $height == 4{
	  box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
	}
	@else if $height == 5{
	  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
	}

}

@mixin SansSerif{
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-style: normal;
}

@mixin Serif{
	font-family: 'Yeseva One', sans-serif;
	font-weight: 400;
	font-style: normal;
}

@mixin font-size($sizeValue: 1.6) {
	font-size: ($sizeValue * 10) + px;
	font-size: $sizeValue + rem;
}
@mixin line-height($sizeValue: 1.6) {
	line-height: ($sizeValue * 10) + px;
	line-height: $sizeValue + rem;
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin transform($property) {
  -webkit-transform: $property;
  -ms-transform: $property;
  transform: $property;
}

@mixin center($position){
	@if $position == "both"{
		position:absolute;
		top:50%;
		left:50%;
		transform:translate(-50%,-50%);
	}
	@if $position == "vertical"{
		position:absolute;
		top:50%;
		transform:translateY(-50%);
	}
	@if $position == "horizontal"{
		position:absolute;
		left:50%;
		transform:translateX(-50%);
	}
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
  background-clip: padding-box;  /* stops bg color from leaking outside the border: */
}

@mixin keyframe ($animation_name) {
    @-webkit-keyframes #{$animation-name}  {
        @content;
    }

    @-moz-keyframes #{$animation-name}  {
        @content;
    }

    @-o-keyframes $animation_name {
        @content;
    }

    @keyframes #{$animation-name}  {
        @content;
    }
}

@mixin animation ($delay, $duration, $animation, $fillmode, $iterations) {
    -webkit-animation-delay: $delay;
    -webkit-animation-duration: $duration;
    -webkit-animation-name: $animation;
    -webkit-animation-fill-mode: $fillmode;
 		-webkit-animation-iteration-count: $iterations;

    -moz-animation-delay: $delay;
    -moz-animation-duration: $duration;
    -moz-animation-name: $animation;
    -moz-animation-fill-mode: $fillmode;
 		-moz-animation-iteration-count: $iterations;

    animation-delay: $delay;
    animation-duration: $duration;
    animation-name: $animation;
    animation-fill-mode: $fillmode;
 		animation-iteration-count: $iterations;
	}
